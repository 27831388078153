import React from "react";

import { NavLink } from "react-router-dom";
import { ROUTES } from "../../consts";

import style from "./NavBar.module.css";
import { useHistory } from "react-router-dom";

const NavBar = () => {
  const history = useHistory();
  return (
    <>
      <div className={style.slider__con}>
        <div>
          <p className={style.slider}>Designer & Creative developer & Designer & Creative developer & Designer & Creative developer & Designer & Creative developer & Designer & Creative developer </p>
          <p className={style.slider}>Designer & Creative developer & Designer & Creative developer & Designer & Creative developer & Designer & Creative developer & Designer & Creative developer </p>

        </div>
      </div>
      <nav className={style.navbar}>
        <button
          className={`${style.button} ${style.back} ${style.button__label}`} onClick={() => { history.goBack(); }}>
          back
        </button>
        <ul className={style.list}>
          <li className={style.link}>
            <NavLink className={`${style.button} ${style.start}`} to={ROUTES.start}>
              <span className={style.button__label}>Home</span>
            </NavLink>
          </li>
          <li className={style.link}>
            <NavLink className={style.button} to={ROUTES.projects}>
              <span className={style.button__label}>Projects</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavBar;
