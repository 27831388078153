import React from "react";
import style from "./Footer.module.css";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../consts";

const Footer = () => {
  return (
    <section className={`${style.container} ${style.background}`}>
      <NavLink className={`${style.button} ${style.talk}`} to={ROUTES.talk}>
        <span className={style.button__label}>Let's Talk</span>
      </NavLink>
    </section>
  );
};

export default Footer;
