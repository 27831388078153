import React, { useState, useEffect } from "react";

import style from "./PostDetail.module.css";
import { useParams } from "react-router-dom";

require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
})

const { REACT_APP_CONTENTFUL_ACCESS_TOKEN, REACT_APP_CONTENTFUL_SPACE_ID } = process.env

const PostDetail = () => {

  const [post, setPost] = useState("");
  const [img, setImg] = useState("");
  const [multimg, setMultimg] = useState("");
  const { id } = useParams();
  console.log(id);

  const query = `
query{
  post(id: "${id}" ){
    sys {
      id
    }
      title
      description
      date
      team
      tags
      time
      linken
      bedrijf
      imagesCollection {
        items {
          title
          url
          description
        }
      }
      casemovie {
      url
      title
      }
      hoofdimg{
        url
        description
        title
      }
      id
    }
}
`

  useEffect(() => {
    fetch(
      `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_CONTENTFUL_SPACE_ID}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${REACT_APP_CONTENTFUL_ACCESS_TOKEN}`
        },
        body: JSON.stringify({
          query
        })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log(response);
        const p = response.data.post
        const img = response.data.post.hoofdimg
        const imgs = response.data.post.imagesCollection.items
        setImg(img)
        setPost(p);
        setMultimg(imgs)
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  console.log(post);
  console.log(img.url);
  console.log(multimg)
  console.log(post.linken);
  const linken = post.linken;

  const date = new Date(`${post.date}`);
  const fullDate = date.getDate() + ' - ' + (date.getMonth() + 1) + ' - ' + date.getFullYear();

  if (!post) { return <div className={style.wrap__load}><span className={style.loading}>Give me a second <br />My project is loading</span> <span className={style.circle}></span></div> } else {

    return (
      <>
        <section className={style.container}>
          <h2 className={style.title}>{post.title}</h2>
          <img className={style.head__img} alt={post.title} src={img.url} width="760" height="428" />
          <ul className={style.details}>
            <li className={style.detail__item}>
              <img className={style.icon__img} alt="agenda icon" src="../assets/img/agenda.png" width="30" height="31.69" />
              <span className={style.icon__span}>{fullDate}</span>
            </li>
            <li className={style.detail__item}>
              <img className={style.icon__img} alt="tijd icon" src="../assets/img/time.png" width="30" height="31.69" />
              <span className={style.icon__span}>{post.time}</span>
            </li>
            <li className={style.detail__item}>
              <img className={style.icon__img} alt="bedrijf icon" src="../assets/img/company.png" width="30" height="31.69" />
              <span className={style.icon__span}>{post.bedrijf}</span>
            </li>
            <li className={style.detail__item}>
              <img className={style.icon__img} alt="agenda icon" src="../assets/img/user.png" width="30" height="31.69" />
              <span className={style.icon__span}>{post.team}</span>
            </li>
          </ul>
          <p className={style.post__des}>{post.description}</p>
          {linken !== null ?
            linken.map(link => (
              <a className={`${style.button}`} href={link.title}>{link.description}</a>
            ))
            :
            <></>}

          {post.casemovie !== null ?
              <video controls autoPlay loop className={`${style.video}`} src={post.casemovie.url}/>
            :
            <></>}

          <div className={style.img__wrap}>
            {!multimg ?
              <div className={style.wrap__load}><span className={style.loading}>Give me a second <br />The images are loading</span> <span className={style.circle}></span></div>
              :
              multimg.map(multi => (
                multi.title === "video" ?
                  <video key={multi.title} src={multi.url} className={style.detail__img} alt={multi.title} controls />
                  :
                  <img key={multi.title} className={style.detail__img} alt={multi.title} src={multi.url} width="681" height="451" />
              ))
            }
          </div>
        </section>
      </>
    );
  }
}

export default PostDetail;
