import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../consts";
import style from "./Start.module.css";

const Start = () => {
  return (
    <div className={style.back__wrap}>

      <nav className={style.navbar}>
        <ul className={style.list}>
          <li className={style.abouts}>
            <NavLink className={`${style.button} ${style.about}`} to={ROUTES.about}>
              <span className={style.button__label}>About me</span>
            </NavLink>
          </li>
          <li className={style.title__wrap}>
            <section className={style.title__wrapper} >
              <h1 className={style.title}>Hi, I'm Gilke Vermander</h1>
              <p className={style.subtitle}>A 21 year old designer and creative developer</p>
            </section>
          </li>
          <li className={style.project}>
            <NavLink className={`${style.button} ${style.projects}`} to={ROUTES.projects}>
              <span className={`${style.button__label} ${style.project__label}`}>Projects</span>
            </NavLink>
          </li>
          <li className={style.talks}>
            <NavLink className={`${style.button} ${style.talk}`} to={ROUTES.talk}>
              <span className={style.button__label}>Let's Talk</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Start;
