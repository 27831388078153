import React from "react";
import style from "./Talk.module.css";

const Talk = () => {
  return (
    <section className={style.container}>
      <h2 className={style.title}>Hi, let's talk</h2>
      <div className={style.mailwrap}>      
        <a className={`${style.mail} ${style.subtitle}`} href="mailto:gilke.vermander@gmail.com">You can send me a lovely email <img className={style.img} src="./assets/img/mail.png" alt="mail logo" title="mail Logo"></img></a>
      </div>
      <div className={style.socials}>
        <p className={style.subtitle}>Checkout my socials</p>
        <div>
          <a href="https://www.instagram.com/gilke.design/?hl=en"><img className={style.img} src="./assets/img/insta.png" alt="Instagram logo" title="Instagram logo"></img></a>
          <a href="https://www.linkedin.com/in/gilke-vermander-1998061b3/"><img className={style.img} src="./assets/img/link.png" alt="Instagram logo" title="Instagram logo"></img></a>
        </div>

      </div>


    </section>
  );
};

export default Talk;
