const ROUTES = {
  start: "/",
  about: "/about",
  example: { path: "/:id", to: "/" },
  projects: "/projects",
  talk: "/talk",
  porjectDetail: { path: "/projects/:id", to: "/projects/" },
};

export { ROUTES };
