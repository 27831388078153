import React, { useState, useEffect } from "react";

import style from "./Projects.module.css";
import { Link } from "react-router-dom";
import { ROUTES } from "../../consts";

require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
})

const { REACT_APP_CONTENTFUL_ACCESS_TOKEN, REACT_APP_CONTENTFUL_SPACE_ID } = process.env

const query = `
query{
  postCollection{
    items{
      title
      description
      date
      team
      tags
      time
      bedrijf
      imagesCollection {
        items {
          title
          url
          description
        }
      }
      hoofdimg{
        url
        description
        title
      }
      id
      sys {
        id
      }
    }
  }
}
`

function hover(e) {
  e.target.style.opacity = '.2';
}

function hoverout(e) {
  e.target.style.opacity = '1';
}

const Projects = () => {
  const [posts, setPosts] = useState("");

  useEffect(() => {
    fetch(
      `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_CONTENTFUL_SPACE_ID}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${REACT_APP_CONTENTFUL_ACCESS_TOKEN}`
        },
        body: JSON.stringify({
          query
        })
      }
    )
      .then(response => response.json())
      .then(json => { setPosts(json.data.postCollection.items) })
      .catch(error => {
        console.log(error);
      })
  }, []);
  console.log(posts);
  if (!posts) { return <div className={style.wrap__load}><span className={style.loading}>Give me a second <br/>My projects are loading</span> <span className={style.circle}></span></div> } else {
    return (
      <>

        <section className={style.container}>
          <h2 className={style.title}>Projects</h2>
          <ul className={style.menu}>
            {posts.map(post => (
              <li className={style.menu__item}  key={post.id}>
                <Link className={style.menu__link} to={`${ROUTES.porjectDetail.to}${post.sys.id}`}>
                  <img onMouseOver={hover} onMouseOut={hoverout} className={style.menu__img} alt={post.title} src={post.hoofdimg.url} width="760" height="428"/>
                  <h3 className={`${style.menu__title}`}>{post.title}</h3>
                  <p className={`${style.menu__tags}`}>{post.tags}</p>
                </Link>
              </li>
            ))
            }
            
          </ul>
          <p className={style.p}  key="more soon">
                More projects comming soon ... &#128513;
          </p>
        </section>
      </>
    );
  }




};

export default Projects;
