import React from "react";
import NavBar from "../NavBar/index";

import Start from "../Start/index";
import Talk from "../Talk/index";
import Projects from "../Projects/index";
import About from "../About/index";
import PostDetail from "../PostDetail/index";
import Footer from "../Footer/index";

import { Switch, Route } from "react-router-dom";
import { ROUTES } from "../../consts/index";

import style from "./Home.module.css";

const Home = () => {
  return (
    <div className={style.contain}>

      <Switch>
        <Route path={ROUTES.talk}>
          <div className={style.container}>
            <NavBar />
            <Talk />
          </div>
        </Route>
        <Route path={ROUTES.porjectDetail.path}>
            <NavBar />
            <PostDetail />
            <Footer/>
        </Route>
        <Route path={ROUTES.projects}>
          <NavBar />
          <Projects />
          <Footer/>
        </Route>
        <Route path={ROUTES.about}>
          <div className={style.container}>
            <NavBar />
            <About />
          </div>
        </Route>
        <Route path={ROUTES.start}>
          <Start />
        </Route>
      </Switch>
    </div>
  )


};

export default Home;
