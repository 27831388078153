import React from "react";

import style from "./About.module.css";

import { NavLink } from "react-router-dom";
import { ROUTES } from "../../consts";

const About = () => {
  return (
    <section className={style.container}>
      <h2 className={style.title}>About me</h2>
      <div className={style.wrap__div} >
        <p className={style.about__text}>
          HI, I am Gilke Vermander, a 21 year old designer and creative developer based in Kortrijk. I’m currently studying Devine at Howest and The School of Arts (KASK). I’m looking for a cool design and/or development based internship.
          My passion for design and development started already when I was in high school. I started to create little project on my own. After high school I decided to go to Kortrijk and start studying Devine.
        </p>
        <img className={style.img} src="./assets/img/me2.jpg" alt="portrait of me" title="picture of me" width="379" height="367"></img>
      </div>
      <div className={`${style.wrap__div} ${style.wrap__wrap}`}>
        <img className={style.img} src="./assets/img/mee.jpg" alt="Portrait of me" title="picture of me" width="379" height="367"></img>
        <div>
          <p className={style.about__text}>
          At Devine I learned a lot about digital design and creative development. I have already made a couple of projects for school, friends, a non-profit organization...

          I love to design and develop creative websites. Creating creative projects starting from a problem is what i’m passionate about.

          Feel free to take a look at some of my projects.
        

        </p>
        <div className={`${style.about__text} ${style.quality__wrap}`}>
        <p className={style.light}>MY SKILLS</p>

            <p className={style.light}>HTML 5 | Arduino | CSS | React | CMS | Javascript | WebGL | Unity | PHP | SQL</p>
            <p className={style.light}>2D and 3D motion | web- and appdesign | UX and UI</p>
          </div>
          <a className={`${style.button__devine}`} href="https://www.devine.be/">checkout Devine</a>
        </div>
        
      </div>
      <NavLink className={`${style.button} ${style.projects}`} to={ROUTES.projects}>
        <span className={style.button__label}>Projects</span>
      </NavLink>
    </section>
  );
};

export default About;
